.light-display {
    padding: 0.4rem;
    border-radius: 0.5rem;
    width: 100%;
    font-size: x-large;
    border: 1px solid;
}

    .light-display.active {
        background: #fBCAC9;
        border-color: #F02D28;
        color: #F02D28;
    }

    .light-display.inactive {
        background: #FFFFFF;
        border-color: #ABABAB;
        color: #ABABAB;
    }
