.custom-dxDataGrid .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
    padding: 0 !important;
    border-left: 10px solid #63A6DF !important;
}

.custom-dxDataGrid .dx-row .dx-header-row > td {
    background-color: #DADADA !important;
    color: #6C6C6C !important;
    vertical-align: middle !important;
    padding-top: 0 !important;
}

.custom-dxDataGrid .child-table-cell {
    background-color: #E3E3E3 !important;
    color: #333333 !important;
    vertical-align: top !important;
    padding-top: 5px !important;
}

.custom-dxDataGrid .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused), .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
    background-color: #BCBCBC !important;
    color: #333333 !important;
}

.custom-dxDataGrid .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
    border-bottom: 1px solid #BCBCBC !important;
}

.custom-dxDataGrid .btn-expand {
    background-color: transparent !important;
    border: 0 !important;
}

.custom-dxDataGrid .dx-datagrid .dx-sort-down:before {
    font-family: DXIcons,sans-serif !important;
    content: "\f001" !important;
    color: #4F4F4F !important;
    font-size: large !important;
}

.custom-dxDataGrid .dx-datagrid .dx-sort-up:before {
    font-family: DXIcons,sans-serif !important;
    content: "\f002" !important;
    color: #4F4F4F !important;
    font-size: large !important;
}

.custom-dxDataGrid .dx-datagrid-rowsview .dx-row {
    border-bottom: 1px solid #E3E3E3 !important;
}

.custom-dxDataGrid .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row > td {
    background-color: #F9F9F9 !important;
}

.custom-dxDataGrid .dx-datagrid .dx-row-lines > td {
    background-color: #F9F9F9 !important;
}
