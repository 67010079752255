.h-spacer-s {
    height: 0.5rem;
}

.h-spacer-m {
    height: 1rem;
}

.filter-column {
    overflow-y: scroll;
}

.btn-group.date-range {
    display: flex;
    text-align: center;
}

.date-range .btn {
    flex: 1 !important;
    display: inline !important;
}

.w-spacer-s {
    display: inline;
}

.grid-header-buttons {
    display: flex !important;
}

@media (max-width: 1524px) {
    .grid-header-buttons .btn-inner-text {
        display: none !important;
    }

    .grid-header-buttons .icon {
        padding-right: 0 !important;
    }
}

.toolbar-display {
    display: flex !important;
    align-items: baseline !important;
}

    .toolbar-display.right {
        justify-content: flex-end !important;
    }

.custom-status-field {
    display: inline-flex;
    height: 100% !important;
}

    .custom-status-field > .error-status-icon {
        display: flex;
        align-items: center;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        width: 30px !important;
        justify-content: center;
    }

    .custom-status-field .status-name {
        border: 0 !important;
    }

.customStatus_TextBox {
    width: 100% !important;
}

    .customStatus_TextBox > .dx-numberbox .dx-texteditor-input,
    .customStatus_TextBox > .dx-textbox.pseudo-class-not\(.dx-textarea\).pseudo-class-not\(.dx-selectbox\).pseudo-class-not\(.dx-searchbox\).pseudo-class-not\(.dx-datebox\).pseudo-class-not\(.date-range-input-dx\) .dx-texteditor-input,
    .customStatus_TextBox > .dx-textbox:not(.dx-textarea):not(.dx-selectbox):not(.dx-searchbox):not(.dx-datebox):not(.date-range-input-dx) .dx-texteditor-input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

/* CSS BELOW OVERRIDES DEVEXTREME CSS FOR TREEVIEW AND SELECTBOXES.
    BE CAREFUL WHEN MAKING CHANGES. */
/* To remove border from items inside lists */
.dx-list-item {
    display: inline-table !important;
    border-bottom: 0px !important;
}

/* To remove background and border of Tree View */
.building-block-gray, .content-navigation-dx-content, .content-navigation__list, .content-navigation__list .content-navigation__list-item-list, .dx-treeview {
    border: 0 !important;
    background-color: transparent !important;
}

.dx-treeview-item {
    border: 0 !important;
}

.dx-datagrid-search-panel {
    margin-left: 0 !important;
}

#treeView_Operators .dx-treeview-item-with-checkbox .dx-checkbox {
    left: 0 !important;
}

#treeView_Operators .dx-treeview.pseudo-class-not\(.content-navigation-dx\) .dx-scrollable-content > .dx-treeview-node-container > .dx-treeview-node.dx-treeview-item-with-checkbox > .dx-treeview-item, .dx-treeview:not(.content-navigation-dx) .dx-scrollable-content > .dx-treeview-node-container > .dx-treeview-node.dx-treeview-item-with-checkbox > .dx-treeview-item {
    padding-left: 2rem !important;
}

/* Master-detail grid */
/* CSS BELOW OVERRIDES DEVEXTREME CSS FOR MASTER-DETAIL GRID.
    BE CAREFUL WHEN MAKING CHANGES. */
#grid_History .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
    padding: 0 !important;
    border-left: 10px solid #63A6DF !important;
}

#grid_History .dx-row .dx-header-row > td {
    background-color: #DADADA !important;
    color: #6C6C6C !important;
    vertical-align: middle !important;
    padding-top: 0 !important;
}

#grid_History .child-table-cell {
    background-color: #E3E3E3 !important;
    color: #333333 !important;
    vertical-align: top !important;
    padding-top: 5px !important;
}

#grid_History .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused), .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
    background-color: #BCBCBC !important;
    color: #333333 !important;
}

#grid_History .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
    border-bottom: 1px solid #BCBCBC !important;
}

#grid_History .btn-expand {
    background-color: transparent !important;
    border: 0 !important;
}

#grid_History .dx-datagrid .dx-sort-down:before {
    font-family: DXIcons,sans-serif !important;
    content: "\f001" !important;
    color: #4F4F4F !important;
    font-size: large !important;
}

#grid_History .dx-datagrid .dx-sort-up:before {
    font-family: DXIcons,sans-serif !important;
    content: "\f002" !important;
    color: #4F4F4F !important;
    font-size: large !important;
}

#grid_History .dx-datagrid-rowsview .dx-row {
    border-bottom: 1px solid #E3E3E3 !important;
}
