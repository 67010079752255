.rtc {
    overflow-y: hidden !important;
    height: 100%;
}

    .rtc .space {
        height: 1vw;
    }

.rtc-background {
    background-color: #F0F0F0;
    height: 100%;
    overflow-y: auto;
}

.rtc-panels-content {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
}

.rtc-controls {
    display: flex;
    align-items: center;
}

.gauge-value {
    display: grid;
    vertical-align: middle;
    align-content: center;
    text-align: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
}

    .gauge-value .amount {
        font-size: 4vw;
        color: #363A3B;
    }

    .gauge-value .units {
        font-size: 1vw;
        color: #9C9C9C;
        margin-top: 1vw;
    }

    .gauge-value .content {
        background-color: #FFFFFF !important;
        margin-right: 9vw;
        margin-left: 9vw;
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

.speed-battery-content {
    background-color: #FFFFFF;
    height: 100%;
}

.rtc-panel.speed-battery {
}

.rtc-panel.lamps {
}

.rtc-panels.performance {
}

.cockpit-lights-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-iso-lamp {
    height: 25px;
}

.map-container {
    height: 100%;
}

.map-type-switcher {
    z-index: 1;
    position: absolute;
    right: 0;
    padding-right: 1rem;
    padding-top: 1rem;
}