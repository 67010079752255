.panel-title {
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 25%;
    background-color: white;
    padding: 0.8rem;
}

    .panel-title > h2 {
        color: #363A3B !important;
    }

.panel-content {
    padding: 0.8rem;
    padding-top: 0 !important;
}

    .panel-content.show {
        display: block;
        background-color: white;
    }

    .panel-content.hide {
        display: none;
    }
