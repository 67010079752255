.vhm-content {
    align-items: unset !important;
    justify-content: unset !important;
    background: #FFFFFF !important;
    margin: 0 !important;
    overflow-y: auto !important;
}

.vhm-init-logo {
    max-height: 40px;
}

.vhm-page-header {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (max-width: 1024px) {
    /* Hides page header in sizes lower than desktop */
    .vhm-page-header {
        display: none;
    }
}

.popup-content {
    padding: 1rem;
}

.popup-action-buttons {
    position: absolute;
    bottom: 1rem;
    text-align: center;
    width: 100%;
}

.btn-popup {
    display: initial !important;
    width: 48%;
}

.search-container {
    display: inline-flex;
}

.align-middle {
    display: flex;
    align-items: center;
}

.w-40 {
    width: 40% !important;
}

.w-60 {
    width: 60% !important;
}

.h-5 {
    height: 5% !important;
}

.h-8 {
    height: 8% !important;
}

.h-10 {
    height: 10% !important;
}

.h-12 {
    height: 12% !important;
}

.h-15 {
    height: 15% !important;
}

.h-20 {
    height: 20% !important;
}

.h-30 {
    height: 30% !important;
}

.h-35 {
    height: 35% !important;
}

.h-45 {
    height: 45% !important;
}

.h-70 {
    height: 70% !important;
}

.h-85 {
    height: 85% !important;
}

.h-88 {
    height: 88% !important;
}

.h-90 {
    height: 90% !important;
}

.h-92 {
    height: 92% !important;
}

.h-95 {
    height: 95% !important;
}

.scroll-v {
    overflow-y: scroll !important;
}

h2 {
    color: #919191 !important;
}