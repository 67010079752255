.vhmLoginPage {
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #416a92;
    background-image: url(../../utils/images/backgroundLogin.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.loginContainer {
    display: flex;
    flex-shrink: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    box-sizing: border-box;
}

.loginArticle {
    align-self: center;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    padding: .5rem 1rem;
}