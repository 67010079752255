.vhm-dashboard-widget {
    padding: 0.5rem;
    height: 50%;
}

    .vhm-dashboard-widget .vhm-dashboard-widget-content {
        background-color: #FFFFFF;
        padding: 0.5rem;
        height: 100%;
        border: 1px solid #E1E1E1;
    }

.vhm-dashboard-content {
    height: 98%;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #F2F2F2;
    border: 1px solid #E1E1E1;
}

@media (max-width: 1024px) {
    .vhm-dashboard-content {
        background-color: #FFFFFF;
        border: 0;
    }
}

.fleet-overview-total {
    font-weight: bold;
    font-size: large;
    height: 100px !important;
    vertical-align: middle !important;
}

.latest-errors-error-list {
    text-align: center !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

    .latest-errors-error-list .error-container {
        border: 1px solid #E1E1E1;
        padding: 0.3rem;
    }

        .latest-errors-error-list .error-container .error-number {
            font-weight: bold;
            font-size: xx-large;
        }

        .latest-errors-error-list .error-container.critical-errors {
            border-top: 5px solid #363A3B;
        }

        .latest-errors-error-list .error-container.errors {
            border-top: 5px solid #F02D28;
        }

        .latest-errors-error-list .error-container.warnings {
            border-top: 5px solid #F69926;
        }

.latest-errors-error-grid {
    height: 100%;
}

    .latest-errors-error-grid .latest-errors-icon {
        padding: 0.3rem;
        border-radius: 0.2rem;
        color: #FFFFFF !important;
    }

        .latest-errors-error-grid .latest-errors-icon.warning {
            background: #ED7D31;
        }

        .latest-errors-error-grid .latest-errors-icon.mil {
            background: #C00000;
        }

        .latest-errors-error-grid .latest-errors-icon.protect {
            background: #FFC000;
        }

        .latest-errors-error-grid .latest-errors-icon.stop {
            background: #C00000;
        }

    .latest-errors-error-grid .errors-amount {
        background: #363A3B;
        color: #FFFFFF !important;
        border-radius: 1rem;
        text-align: center;
    }

.error-display {
    display: inline-flex;
    width: 25px !important;
}

.number-display {
    padding: 0.2rem;
    border-radius: 0.5rem;
    width: 100%;
    font-size: 12px;
}

    .number-display.error {
        border: 1px solid #F02D28;
        color: #F02D28;
    }

        .number-display.error.active {
            background: #F02D28;
            color: #FFFFFF;
        }

    .number-display.critical {
        border: 1px solid #363A3B;
        color: #363A3B;
    }

        .number-display.critical.active {
            background: #363A3B;
            color: #FFFFFF;
        }

    .number-display.warning {
        border: 1px solid #F69926;
        color: #F69926;
    }

        .number-display.warning.active {
            background: #F69926;
            color: #000000;
        }
