.vehicle-grid-details {
    background-color: #E1E1E1;
    padding: 0.5rem;
}

    .vehicle-grid-details .vehicle-info {
        padding: 0.5rem;
    }

        .vehicle-grid-details .vehicle-info .details {
            background-color: #F8F8F8;
            border: 1px solid #D4D4D4;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

            .vehicle-grid-details .vehicle-info .details .title {
                font-size: large !important;
            }

.details .details-row {
    height: 50%;
    align-items: center;
}

.error-status-icon {
    padding: 0.2rem;
    border-radius: 0.2rem;
    color: #FFFFFF !important;
}

    .error-status-icon.ok {
        background: #56B150;
    }

    .error-status-icon.warning {
        background: #F69926;
    }

    .error-status-icon.error {
        background: #F02D28;
    }

    .error-status-icon.critical {
        background: #363A3B;
    }

.error-details {
    text-align: justify;
    white-space: normal;
    color: #919191 !important;
}

.timeline-scale {
    background-color: #e1e1e1;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.timeline-scale-controls {
    display: table !important;
}

.timeline-hour-label {
    display: table-cell !important;
    vertical-align: middle !important;
}

.btn-scale {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.scheduler-space {
    height: 1rem !important;
}

.last-change-custom-placeholder {
    padding-left: 15px !important;
}

/* CSS BELOW OVERRIDES SPLIT CSS.
    BE CAREFUL WHEN MAKING CHANGES. */
.react-split > .split-container.vertical > .primary {
    border: 0 !important;
}

.react-split > .split-container.vertical > .primary {
    background-color: transparent !important;
}

/* CSS BELOW OVERRIDES DEVEXTREME CSS FOR SCHEDULER.
    BE CAREFUL WHEN MAKING CHANGES. */
.dx-datagrid .dx-datagrid-header-panel .dx-toolbar {
    background-color: transparent !important;
}

/*td[role=columnheader] {*/ /* Center header of tables */
/*text-align: center !important
}*/

/*td[role=gridcell] {
    text-align: center !important;*/ /* Center grid cells */
/*}*/

/* Changes background color of detail in Master-detail grid */
.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
    background-color: #FFFFFF !important;
}

.dx-scheduler-appointment {
    opacity: 0.4 !important;
    height: 25px !important;
    margin-top: -25px !important;
}

.dx-scheduler-all-day-panel { /*Removes dxScheduler default header */
    display: none !important;
}

.dx-scheduler-date-table-cell, .dx-scheduler-header-panel-cell { /*Distancing between hours */
    width: 50px !important;
}

.dx-scheduler-all-day-panel-space {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

.dx-scheduler-header {
    display: none !important;
}

.dx-scheduler-work-space {
    padding-top: 0px !important;
    margin-top: 0 !important;
}

.dx-scheduler-work-space {
    border: none !important;
}

.dx-scheduler-work-space-day .dx-scrollable {
    padding-bottom: 0px !important;
}

.dx-scheduler-work-space-day .dx-scrollable {
    padding-bottom: 0px !important;
}

.dx-scheduler-header-panel-cell {
    background-color: transparent !important;
    color: #000000 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.dx-scheduler-header .dx-widget {
    display: none !important;
}

/*Height of cells of apointments*/
.dx-scheduler-group-flex-container .dx-scheduler-group-row .dx-scheduler-group-header {
    height: 25px !important;
    min-height: 26px !important;
}

.dx-scheduler-timeline .dx-scheduler-date-table .dx-scheduler-date-table-cell {
    height: 25px !important;
    min-height: 26px !important;
}

.dx-list-item.pseudo-class-first-of-type,
.dx-list-item.pseudo-class-last-of-type,
.dx-list-item:first-of-type,
.dx-list-item:last-of-type {
    border: 0px !important;
}

.dx-scheduler-timeline .dx-scheduler-date-table, .dx-scheduler-timeline .dx-scheduler-date-table-scrollable .dx-scrollable-content, .dx-scheduler-timeline .dx-scheduler-group-table, .dx-scheduler-timeline .dx-scheduler-sidebar-scrollable .dx-scrollable-content {
    min-height: 50px !important;
}

.dx-scheduler-work-space-both-scrollbar .dx-scheduler-date-table {
    height: 50px !important;
}

/* Custom Vehicle Errors grid */

.timeline-show {
    display: block;
}

.timeline-hide {
    display: none;
}

.btn-timeline {
    border: 0 !important;
    background-color: transparent !important;
}

.custom-dxdatagrid-header {
    padding: 0.5rem !important;
}

.custom-dxdatagrid-row {
    background-color: #F9F9F9 !important;
    padding: 0.5rem !important;
}

#grid_VehicleErrors tr.dx-row.dx-row-lines.dx-master-detail-row {
    border-top: 0 !important;
}

#grid_VehicleErrors .dx-datagrid-rowsview .dx-row {
    border-top: 10px solid #E1E1E1 !important;
    padding: 1rem !important;
    background-color: #E1E1E1 !important;
}

#grid_VehicleErrors .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
    background-color: #F9F9F9 !important;
}

#grid_VehicleErrors .dx-scheduler-work-space-both-scrollbar.dx-scheduler-timeline:not(.dx-scheduler-work-space-grouped) .dx-scheduler-header-scrollable.dx-scrollable, .dx-scheduler-work-space-both-scrollbar.dx-scheduler-work-space-month .dx-scheduler-header-scrollable.dx-scrollable {
    background-color: #F9F9F9 !important;
}

#grid_VehicleErrors .dx-scheduler-work-space-both-scrollbar .dx-scheduler-date-table {
    background-color: #F9F9F9 !important;
}

#grid_VehicleErrors .dx-datagrid-content {
    background-color: #E1E1E1 !important;
}
