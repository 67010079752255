.custom-dxList .dx-list-group-header {
    color: #63A6DF;
    padding: 0.5rem 0 0.5rem 0;
    border-bottom: 2px solid #F4F4F4 !important;
    border-top: 0 !important;
    background-color: transparent !important;
    display: flex !important;
    align-items: center !important;
}

    .custom-dxList .dx-list-group-header::before {
        float: left !important;
        margin-right: 5px !important;
        border-top-color: #63A6DF !important;
        content: "\f016" !important;
        font-family: DXIcons,sans-serif !important;
        font-weight: normal !important;
        border: 0 !important;
        display: flex !important;
        align-items: center !important;
        padding-right: 1rem !important;
    }

.custom-dxList .dx-list-group-collapsed > .dx-list-group-header {
    color: #9D9D9D !important;
}

    .custom-dxList .dx-list-group-collapsed > .dx-list-group-header::before {
        content: "\f010" !important;
        border-top-color: #9D9D9D !important;
    }

.custom-dxList .dx-list-group-body {
    padding-left: 1rem;
}

.custom-dxList .dx-list-item {
    border-top: 1px solid #F4F4F4 !important;
    border-bottom: 1px solid #F4F4F4 !important;
}

    .custom-dxList .dx-list-item:first-of-type {
        border-top: 0 !important;
    }
