/* Solves glitch caused by Link element in React */
a {
    border: 0 !important;
    color: transparent !important;
}

.navbar-pageTitle {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-flow: column-reverse;
    flex-flow: column-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

.navbar-dropdown {
    position: absolute !important;
}

.nav-item .active {
    background-color: #63A6DF !important;
    color: #FFFFFF !important;
}